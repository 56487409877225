import React, {useContext, useMemo, useRef} from 'react';
import {RadioGroupContext} from '../../molecules/RadioGroup';
import {RadioButtonProps} from './types';

const RadioButton = ({children, size = 'medium', value}: RadioButtonProps) => {
  const {name, selectedValue, onChange, ...liAttr} = useContext(RadioGroupContext);
  const isChecked = useMemo(() => selectedValue === value, [selectedValue, value]);
  const labelRef = useRef<HTMLLabelElement>(null);

  const sizeStyles = {
    medium: {
      wrapper: '-top-0.5 h-6 w-6',
      dot: 'h-2 w-2',
      labelPadding: 'pl-8',
      text: 'text-base leading-6 md:text-sm md:leading-5',
    },
    small: {
      wrapper: 'top-0 h-5 w-5',
      dot: 'h-1.5 w-1.5',
      labelPadding: 'pl-7',
      text: 'text-sm leading-5',
    },
  };

  const {wrapper, dot, labelPadding, text} = sizeStyles[size];

  const radioWrapperClasses = `
    radio absolute left-0 group
    ${wrapper}
    rounded-full border-2
    transition-all duration-200 ease-in-out
    group-hover:border-gray-400 group-hover:bg-gray-100
    ${isChecked ? 'border-blue-500 bg-white group-hover:bg-blue-50 group-hover:border-blue-500' : 'border-gray-400 bg-white'}
  `;

  const dotClasses = `
    absolute ${dot} rounded-full
    transition-all duration-200 ease-out
    ${isChecked ? 'bg-blue-500' : 'bg-transparent group-hover:bg-gray-500'}
    left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
  `;

  return (
    <div {...liAttr} className="relative radio-button">
      <label
        ref={labelRef}
        htmlFor={`radio-${value}`}
        className={`flex items-center cursor-pointer 'border-blue-500 select-none relative ${labelPadding} ${isChecked ? 'is-active' : ''}`}
      >
        <input
          id={`radio-${value}`}
          name={name}
          value={String(value)}
          type="radio"
          aria-checked={isChecked}
          onChange={() => onChange?.(value)}
          className="sr-only"
        />
        <div className={radioWrapperClasses}>
          <div className={dotClasses} />
        </div>
        <div className={text}>{children}</div>
      </label>
    </div>
  );
};

export default RadioButton;
