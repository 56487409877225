import {NotificationProps} from '../../molecules/Notification';

export const getNotificationHeaderText = (type?: NotificationProps['type']) => {
  switch (type) {
    case 'Error':
      return 'Rendering Error';
    case 'Warning':
      return 'Warning';
    case 'Information':
      return 'Information';
    default:
      return 'Success';
  }
};
