import React, { ReactNode } from 'react';
import posed from 'react-pose';

const Collapsible = posed.div({
  closed: { height: 0 },
  open: { height: "auto" },
});

interface CollapsibleWrapperProps {
  children: ReactNode;
  isActive: boolean;
  isOpen: boolean;
}

const CollapsibleWrapper = ({ children, isActive, isOpen }: CollapsibleWrapperProps): JSX.Element => {
  if (isActive) {
    return (
      <Collapsible className="DropdownInner-collapsible" pose={isOpen ? "open" : "closed"}>
        {children}
      </Collapsible>
    );
  }
  return <>{children}</>;
};

export default CollapsibleWrapper;
