import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import './HighlightNotificationWrapper.scss';
import CountBadge from 'components/atoms/CountBadge/CountBadge';

type Flavor = 'primary' | 'regular' | 'attention' | 'cta' | 'action';
type HighlightNotificationWrapperProps = {
  showBadge: boolean;
  badge: number;
  badgeFlavor: Flavor;
  icon: ReactNode;
  children: ReactNode;
  additionalClassName?: string;
} & HTMLAttributes<HTMLDivElement>;

const HighlightNotificationWrapper = (props: HighlightNotificationWrapperProps) => {
  const {
    showBadge,
    badge,
    badgeFlavor,
    icon,
    children,
    additionalClassName,
    ...spanAttr
  } = props;

  const allowBadge = useMemo(() => showBadge && badge > 0, [showBadge, badge]);
  const notificationClassName = useMemo(() => {
    return classNames({
      HighlightNotificationWrapper: true,
      [additionalClassName!]: !!additionalClassName,
    });
  }, [additionalClassName]);

  return (
    <>
      <span {...spanAttr} className={notificationClassName}>
        {allowBadge && (
          <span className="badge">
            <CountBadge flavor={badgeFlavor} count={badge} />
          </span>
        )}
        {icon}
      </span>
      {children}
    </>
  );
};

export default HighlightNotificationWrapper;
