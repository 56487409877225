import { useEffect, useRef } from 'react';

import type { Options as PopperOptions } from '@popperjs/core';
import { createPopper } from '@popperjs/core/lib/popper-lite.js';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

function usePopper<T extends HTMLElement>(options: Partial<PopperOptions> = {}) {
  const anchorRef = useRef<T>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  useEffect(function initPopper() {
    if (!anchorRef?.current || !dropdownRef?.current) return;

    const popperInstance = createPopper(anchorRef.current, dropdownRef.current, {
      modifiers: [flip, preventOverflow],
      placement: 'bottom-end',
      strategy: 'fixed',
      ...options,
    } as Partial<PopperOptions>);

    return () => {
      popperInstance?.destroy();
    };
  }, [options]);

  return { anchorRef, dropdownRef };
}

export default usePopper;
