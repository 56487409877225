import React from 'react';

const ListerButtons: React.FC<{
  buttons?: JSX.Element[];
}> = ({buttons}) => {
  if (!buttons || buttons.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col items-stretch gap-3 w-full sm:max-w-40 erg-lister-buttons" data-testid="erg-lister-buttons">
      {buttons.map((button, index) => (
        <React.Fragment key={index}>{button}</React.Fragment>
      ))}
    </div>
  );
};

export default ListerButtons;
