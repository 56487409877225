import React, { ReactElement, useCallback } from 'react';
import { ReactSVG } from 'react-svg';

import iconBurger from '@ergeon/icons/svg/icon-burger.svg';

import Button from '../../../atoms/Button';
import { useIsMobile } from '../../../utils/useResponsiveBreakpoint';
import MainMenu from '../../../molecules/MainMenu/MainMenu';
import type {MainMenuProps} from '../../../molecules/MainMenu/MainMenu';
import { TopPanelNavSide } from './TopPanelNav'
import type { TopPanelNavListProps } from './TopPanelNav'

interface TopPanelAppNavProps extends TopPanelNavListProps, MainMenuProps {}

const TopPanelAppNav = (props: TopPanelAppNavProps) => {
  const { buttons, children, renderToggle } = props;

  const isMobile = useIsMobile();

  const renderToggleMobile: MainMenuProps['renderToggle'] = useCallback(props => (
    <Button
      className="top-panel__nav--toggle"
      flavor="regular"
      size="large"
      taste="boundless"
      {...props}>
        <ReactSVG src={iconBurger} />
    </Button>
  ), []);

  if (isMobile) {
    return <MainMenu buttons={buttons} renderToggle={renderToggleMobile} />;
  }
  return (
    <TopPanelNavSide>
      {children as ReactElement}
      <MainMenu buttons={buttons} renderToggle={renderToggle} />
    </TopPanelNavSide>
  );
};

export default TopPanelAppNav;
