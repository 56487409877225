import React from 'react';

import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import classNames from 'classnames';

import Breadcrumb from '../../molecules/Breadcrumb';
import ButtonGroup from '../../molecules/ButtonGroup';
import SearchInput from '../../atoms/SearchInput';
import ProjectsCard from '../../molecules/ProjectsCard';

import './index.scss';

class CardsList extends React.Component {
  static get propTypes() {
    return {
      breadcrumbItems: PropTypes.array,
      items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
      })),
      maxSize: PropTypes.number,
    };
  }

  static defaultProps = {
    items: [],
    breadcrumbItems: [],
    maxSize: 1,
  };

  state = {
    orderBy: 'size',
    display: 'grid',
    search: '',
  };

  onSortByChange(index) {
    const orderBy = (index === 0) ? 'size' : 'name';
    this.setState({ orderBy });
  }

  onDisplayChange(index) {
    const display = (index === 0) ? 'grid' : 'list';
    this.setState({ display });
  }

  onSearchChange(event) {
    this.setState({
      search: event.target.value,
    });
  }

  getItems() {
    const { items: _items } = this.props;
    const { search, orderBy } = this.state;
    let items = [..._items];

    if (search) {
      items = items.filter(tag => includes(tag.name.toLowerCase(), search.toLowerCase()));
    }

    if (orderBy === 'size') {
      items = items.sort((tag1, tag2) => tag2.size-tag1.size);
    } else {
      items = items.sort((tag1, tag2) => tag1.name.localeCompare(tag2.name));
    }

    return items;
  }

  getMaxSize() {
    // eslint-disable-next-line prefer-const
    let { maxSize, items } = this.props;

    items.forEach(item => maxSize = Math.max(item.size, maxSize));

    return maxSize;
  }

  render() {
    const { breadcrumbItems } = this.props;
    const { orderBy, display, search } = this.state;
    const items = this.getItems();
    const classes = classNames({
      'cards-list' : true,
      'full-width': display === 'list',
      'no-items': !items.length,
    });
    return (
      <div className="wrapper-1180 wrapper-page-content">
        <div className="flex-wrapper nav-section">
          <Breadcrumb className="cards-list--breadcrumb" items={breadcrumbItems} />
          <div className="display-flex cards-list--filters">
            <ButtonGroup
              className="separate-right cards-list--button-group"
              items={[{ label: '123' }, { label: 'A..Z' }]}
              onChange={this.onSortByChange.bind(this)}
              selectedIndex={(orderBy === 'name') ? 1 : 0}/>
            <ButtonGroup
              className="separate-right cards-list--button-group"
              items={[{ label: 'Grid' }, { label: 'List' }]}
              onChange={this.onDisplayChange.bind(this)}
              selectedIndex={(display === 'list') ? 1 : 0}/>
            <SearchInput onChange={this.onSearchChange.bind(this)} value={search} />
          </div>
        </div>
        <div className={classes}>
          {items.map(({ name, slug, size, to, id }) => (
            <ProjectsCard
              key={id}
              maxSize={this.getMaxSize()}
              size={size}
              slug={slug}
              title={name}
              to={to}/>
          ))}
          {!items.length ? <span>No items found</span> : null}
        </div>
      </div>
    );
  }
}

export default CardsList;
