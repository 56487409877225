import React from 'react';
import {ChevronRightIcon} from '@ergeon/icons/hero/mini';
import {UIFlavor} from '../../types';

type InfoButtonsProps = {
  flavor: Exclude<UIFlavor, 'base' | 'transparent' | 'secondary'>;
};

const InfoButton: React.FC<InfoButtonsProps> = ({flavor}) => {
  const flavorColors: Record<typeof flavor, string> = {
    action: 'text-orange-500',
    primary: 'text-blue-500',
    cta: 'text-green-500',
    regular: 'text-gray-500',
    attention: 'text-red-500',
  };

  return (
    <div className="flex flex-col justify-center items-stretch gap-3 erg-info-button" data-testid="erg-info-button">
      <ChevronRightIcon className={`${flavorColors[flavor]} size-5`} />
    </div>
  );
};

export default InfoButton;
