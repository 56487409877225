import React from 'react';
import MessageItem from './MessageItem';
import MessageFeedEmpty from './MessageFeedEmpty';
import {MessageItemProps} from './types';

interface MessageFeedProps {
  messages: MessageItemProps[];
}

const MessageFeed: React.FC<MessageFeedProps> = ({messages}) => {
  const renderMessages = () =>
    messages.length === 0 ? (
      <MessageFeedEmpty />
    ) : (
      messages.map((message, index) => <MessageItem key={index} {...message} />)
    );

  return <div className="flex flex-col gap-4 erg-message-feed">{renderMessages()}</div>;
};

export default MessageFeed;
