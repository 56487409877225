import React from "react";
import ImageSlider from "../ImageSlider/ImageSlider";
import { MediaProps } from "./types";

const QuoteLineMedia: React.FC<MediaProps> = ({ mediaData }) => {
  if (!mediaData || mediaData.length === 0) return null;

  return (
    <div className="relative min-w-16 w-24 h-24 md:w-32 md:h-32">
      <ImageSlider images={mediaData.map(media => media.url)} fit="contain" aspect="square" />
    </div>
  );
};

export default QuoteLineMedia;
