import React from 'react';
import {UIFlavor} from '../../types';

type InfoIconProps = {
  icon: JSX.Element;
  flavor: Exclude<UIFlavor, 'base' | 'transparent' | 'secondary'>;
};

const InfoIcon: React.FC<InfoIconProps> = ({icon, flavor}) => {
  const flavorColors: Record<typeof flavor, string> = {
    action: 'bg-orange-500',
    primary: 'bg-blue-500',
    cta: 'bg-green-500',
    regular: 'bg-gray-500',
    attention: 'bg-red-500',
  };

  const styledIcon = React.cloneElement(icon, {
    className: `${icon.props.className || ''} text-white`,
  });

  return (
    <div
      className={`self-top ${flavorColors[flavor]} p-2 rounded-full aspect-square erg-info-icon size-11`}
      data-testid="erg-info-icon"
    >
      {styledIcon}
    </div>
  );
};

export default InfoIcon;
