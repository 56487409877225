import React, {useMemo} from 'react';

import isNil from 'lodash/isNil';
import {ReactSVG} from 'react-svg';
import classNames from 'classnames';
import Tooltip from '../../atoms/Tooltip';
import iconSuccess from '@ergeon/icons/svg/icon-success.svg';
import iconWarning from '@ergeon/icons/svg/icon-warning.svg';
import iconError from '@ergeon/icons/svg/icon-error.svg';
import iconBadgeNew from '@ergeon/icons/svg/icon-badge-new.svg';
import iconDropDown from '@ergeon/icons/svg/icon_drop_down.svg';

import './HeaderRow.scss';

export enum HeaderRowIconPositions {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum HeaderRowApprovalStatus {
  APPROVED,
  WARNING,
  ERROR,
}

interface OptionProps {
  title: string;
}

interface HeaderRowInterface {
  className?: string;
  approvalStatus?: HeaderRowApprovalStatus;
  tooltipMessage?: string;
  iconPosition?: HeaderRowIconPositions;
  onClick: (options: OptionProps) => void;
  title: string;
  promo?: boolean;
}

const iconNode = <ReactSVG className="header-row__icon" src={iconDropDown} />;

const HeaderRow = (props: HeaderRowInterface) => {
  const {
    approvalStatus,
    iconPosition = HeaderRowIconPositions.RIGHT,
    className = '',
    title,
    tooltipMessage,
    onClick,
    promo,
  } = props;

  const handleClick = () => {
    onClick({title});
  };

  const rowClasses = useMemo(
    () =>
      classNames({
        'header-row': true,
        'header-row--left-icon': iconPosition === HeaderRowIconPositions.LEFT,
        promo,
        [className]: Boolean(className),
      }),
    [iconPosition, promo, className],
  );

  const showStatus = useMemo(() => {
    if (isNil(approvalStatus)) return;

    if (approvalStatus === HeaderRowApprovalStatus.APPROVED) {
      return <ReactSVG className="icon-check-mark" src={iconSuccess} />;
    }

    if (approvalStatus === HeaderRowApprovalStatus.WARNING) {
      return (
        <Tooltip msg={tooltipMessage} position="right">
          <ReactSVG className="icon-warning" src={iconWarning} />
        </Tooltip>
      );
    }

    return (
      <Tooltip msg={tooltipMessage} position="right">
        <ReactSVG className="icon-error" src={iconError} />
      </Tooltip>
    );
  }, [approvalStatus, tooltipMessage]);

  const showBadgeIcon = useMemo(() => promo && <ReactSVG className="promo-icon-badge" src={iconBadgeNew} />, [promo]);

  const showLeftHeaderIcon = useMemo(() => {
    if (iconPosition === HeaderRowIconPositions.LEFT) {
      return iconNode;
    }
  }, [iconPosition]);

  const showRightHeaderIcon = useMemo(() => {
    if (iconPosition === HeaderRowIconPositions.RIGHT) {
      return iconNode;
    }
  }, [iconPosition]);

  return (
    <div className={rowClasses}>
      <div className="header-row__title" onClick={handleClick}>
        {showLeftHeaderIcon}
        <span className="uppercase label">{title}</span>
        {showBadgeIcon}
        {showStatus}
        {showRightHeaderIcon}
      </div>
    </div>
  );
};

export default HeaderRow;
