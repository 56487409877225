import React, {useCallback} from 'react';

import {DropdownButton} from '../../molecules/UniversalDropdown';
import type {MainMenuButton, MainMenuInnerButton} from './types';
import DropdownInnerCollapsible from './DropdownInnerCollapsible';

const useRenderDropdownButtons = (onClick?: () => void) => {
  const callAfterOnClick = useCallback(
    (callback?: () => void) => () => {
      onClick?.();
      callback?.();
    },
    [onClick],
  );

  const renderDropdownButton = useCallback(
    ({icon, label, onClick: buttonOnClick, ...otherProps}: MainMenuButton) => (
      <DropdownButton {...otherProps} key={label} onClick={callAfterOnClick(buttonOnClick)}>
        {icon}
        {label}
      </DropdownButton>
    ),
    [],
  );

  const renderCollapsible = useCallback(
    (button: MainMenuInnerButton) => (
      <DropdownInnerCollapsible button={button} key={button.label} renderDropdownButton={renderDropdownButton} />
    ),
    [],
  );

  return (buttons: Array<MainMenuButton | MainMenuInnerButton>) =>
    buttons.map((button) =>
      (button as MainMenuInnerButton).buttons
        ? renderCollapsible(button as MainMenuInnerButton)
        : renderDropdownButton(button),
    );
};

export default useRenderDropdownButtons;
