import React, {useState} from 'react';
import DotNavigation from './DotNavigation';
import {ImageFit, ImageAspect} from 'components/atoms/ImageContainer/types';
import ImageContainer from '../../atoms/ImageContainer';
import NavigationControls from './NavigationControls';

export interface ImageSliderProps {
  images?: string[];
  fit?: ImageFit;
  aspect?: ImageAspect;
}

const ImageSlider: React.FC<ImageSliderProps> = ({images = [], fit = 'contain', aspect = 'square'}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const shouldShowNavigation = images.length > 1;

  return (
    <div className="relative w-full h-[fit-content] erg-image-slider group">
      <ImageContainer
        fit={fit}
        aspect={aspect}
        src={images[activeIndex]}
        altText={`Preview ${activeIndex + 1}`}
        hasHover={images?.length > 1}
      />
      {shouldShowNavigation && (
        <>
          <NavigationControls imagesLength={images.length} setActiveIndex={setActiveIndex} />
          <DotNavigation imagesLength={images.length} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        </>
      )}
    </div>
  );
};

export default ImageSlider;
