import React from 'react';
import classNames from 'classnames';
import {BadgeProps} from './types';

const Badge: React.FC<BadgeProps> = ({value, flavor = 'attention', shape = 'circle'}) => {
  const flavorClasses: Record<typeof flavor, string> = {
    primary: 'bg-blue-500',
    regular: 'bg-gray-500',
    action: 'bg-orange-400',
    cta: 'bg-green-500',
    attention: 'bg-red-600',
    base: 'bg-gray-700',
  };

  const shapeClasses = {
    circle: 'rounded-full',
    square: 'rounded',
  }

  return (
    <div
      className={classNames(
        'w-min h-min inline-flex items-center' +
        ' min-w-5 min-h-5 font-base leading-none text-white' +
        ' justify-center px-1.5 text-xs',
        shapeClasses[shape],
        flavorClasses[flavor],
      )}
    >
      {value}
    </div>
  );
};

export default Badge;
