import { RefObject, useCallback, useEffect, useState } from 'react';

import addOutsideClick from '../../utils/addOutsideClick';

type UseDropdown<T extends HTMLElement> = {
  anchorRef: RefObject<T>,
  dropdownRef: RefObject<HTMLUListElement>,
};

function useDropdown<T extends HTMLElement>({ anchorRef, dropdownRef }: UseDropdown<T>) {
  const [visible, setVisible] = useState(false);

  const onOutsideClick = useCallback((event: MouseEvent | TouchEvent) => {
    if ([anchorRef, dropdownRef].some(ref => ref.current?.contains(event.target as Node))) {
      return;
    }
    setVisible(false);
  }, []);

  useEffect(function handleOutsideClick() {
    if (!visible) return;

    const removeOutsideClick = addOutsideClick(onOutsideClick)
    return removeOutsideClick;
  }, [visible]);

  return { setVisible, visible };
}

export default useDropdown;
