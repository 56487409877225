import React from 'react';
import classNames from 'classnames';
import {CardProps} from './types';

export const Card: React.FC<CardProps> = ({
  children,
  className = '',
  flavor = 'base',
  rounded = 'small',
  zIndex = '0',
  tag = 'div',
  ...rest
}) => {
  const Node = tag;

  // Base
  const baseClasses = 'flex outline transition outline-1';

  // Flavour
  const flavorClasses = {
    base: 'outline-gray-300 bg-white shadow-gray-600/20',
    regular: 'outline-gray-300 bg-gray-100 shadow-gray-600/20',
    primary: 'outline-blue-200 bg-blue-100 shadow-blue-600/20',
    cta: 'outline-green-200 bg-green-100 shadow-green-600/20',
    secondary: 'outline-green-200 bg-green-100 shadow-green-600/20',
    action: 'outline-orange-200 bg-orange-100 shadow-orange-600/20',
    attention: 'outline-red-200 bg-red-100 shadow-red-600/20',
    transparent: 'outline-transparent bg-transparent shadow-transparent',
  };

  // zIndex
  const zIndexClasses = {
    '0': 'shadow-none z-0',
    '1': 'shadow z-10',
    '2': 'shadow-md z-20',
    '3': 'shadow-lg z-30',
    '4': 'shadow-xl z-40',
  };

  // Rounded
  const roundedClasses = {
    none: 'rounded-none',
    xsmall: 'rounded',
    small: 'rounded-md',
    medium: 'rounded-xl',
    large: 'rounded-2xl',
    xlarge: 'rounded-[20px]',
  };

  // ClassNames
  const classes = classNames(
    baseClasses,
    flavorClasses[flavor],
    zIndexClasses[zIndex],
    roundedClasses[rounded],
    className,
  );

  return (
    <Node className={classes} {...rest}>
      {children}
    </Node>
  );
};

export default Card;
