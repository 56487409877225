import React, {useCallback} from 'react';
import classNames from 'classnames';
import {CheckboxProps} from './types';
import { CheckIcon } from '@ergeon/icons/hero/micro';

const Checkbox = (props: CheckboxProps) => {
  const {checked = false, children, className, disabled = false, onClick, size = 'medium', ...labelAttr} = props;

  const handleClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onClick(e.target.checked);
    },
    [onClick],
  );

  const sizeClasses = {
    medium: 'min-h-6 min-w-6 pl-8',
    small: 'min-h-5 min-w-5 pl-7',
  };

  const checkmarkSizeClasses = {
    medium: 'h-6 w-6',
    small: 'h-5 w-5',
  };

  const containerClasses = classNames(
    className,
    'relative inline-flex items-center select-none',
    'text-base leading-6 md:text-sm md:leading-5',
    sizeClasses[size],
    disabled ? 'cursor-default' : 'cursor-pointer',
  );

  const baseCheckmarkClasses = [
    'absolute left-0 top-0 flex items-center justify-center',
    'border-2 rounded transition-colors duration-200',
    checkmarkSizeClasses[size],
  ];

  const checkmarkStateClasses = {
    uncheckedEnabled: 'bg-white border-gray-400 group-hover:border-gray-400 group-hover:bg-gray-100',
    checkedEnabled: 'bg-white border-blue-500 group-hover:bg-blue-50',
    disabled: 'bg-gray-50 border-gray-300',
  };

  const currentCheckmarkState = disabled
    ? checkmarkStateClasses.disabled
    : checked
      ? checkmarkStateClasses.checkedEnabled
      : checkmarkStateClasses.uncheckedEnabled;

  const checkmarkClasses = classNames(baseCheckmarkClasses, currentCheckmarkState);

  const iconWrapperClasses = 'transition-colors duration-200 h-full w-full flex items-center justify-center';

  const baseIconClasses = 'size-6 transition-colors duration-200';
  const iconStateClasses = {
    uncheckedEnabled: 'text-transparent stroke-transparent group-hover:text-gray-500 group-hover:stroke-gray-500',
    checkedEnabled: 'text-blue-500 stroke-blue-500 group-hover:text-blue-500',
    disabled: 'stroke-gray-300 text-gray-300',
  };

  const currentIconState = disabled
    ? iconStateClasses.disabled
    : checked
      ? iconStateClasses.checkedEnabled
      : iconStateClasses.uncheckedEnabled;

  const iconClasses = classNames(baseIconClasses, currentIconState);

  return (
    <label {...labelAttr} className={classNames(containerClasses, 'group')} data-testid="checkbox-label">
      <input type="checkbox" checked={checked} disabled={disabled} onChange={handleClick} className="sr-only" />
      <div className={checkmarkClasses} data-testid="checkbox-checkmark">
        <div className={iconWrapperClasses}>
          <CheckIcon className={iconClasses} data-testid="checkbox-icon" />
        </div>
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
