import React from 'react';

import classNames from 'classnames';

import ChristmasHatImg from '../../../assets/hat.png';
import {isChristmasTime} from '../../../libs/utils/utils';
import ErgeonLink from '../../atoms/ErgeonLink';
import type {ErgeonLinkProps} from '../../atoms/ErgeonLink';

import './TopPanelLogo.scss';

interface TopPanelLogoProp extends ErgeonLinkProps {
  showChristmasHat?: boolean;
  showPROLabel?: boolean;
}

const TopPanelLogo = (props: TopPanelLogoProp) => {
  const {children, className, showChristmasHat = isChristmasTime(new Date()), showPROLabel, ...linkProps} = props;

  return (
    <ErgeonLink
      aria-label="Ergeon logo"
      className={classNames('top-panel__logo ergeon-logo', className)}
      {...linkProps}
    >
      {children}
      {showChristmasHat && <img className="logo-christmas-hat" src={ChristmasHatImg} />}
      {showPROLabel && <span className="logo-pro-label">PRO</span>}
    </ErgeonLink>
  );
};

export default TopPanelLogo;
