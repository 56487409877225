import React, { Children, forwardRef, HTMLAttributes, ReactElement, ReactNode } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import './Dropdown.scss';

interface DropdownProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode;
  visible: boolean;
}

const Dropdown = forwardRef<HTMLUListElement, DropdownProps>((props, ref) => {
  const { children, className, visible, ...ulProps } = props;

  if (!visible) return null;

  return (
    <Portal node={document?.body}>
      <ul className={classNames('Dropdown', className)} ref={ref} {...ulProps}>
        {Children.map(children, (child, idx) => (
          <li className="Dropdown-item" key={(child as ReactElement)?.key ?? idx}>{child}</li>
        ))}
      </ul>
    </Portal>
  );
});

export default Dropdown;
