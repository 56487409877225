import React from 'react';

type InfoContentProps = {
  title?: string;
  content?: JSX.Element;
};

const InfoContent: React.FC<InfoContentProps> = ({title, content}) => {
  if (!title && !content) {
    throw new Error('Either title or content must be provided.');
  }

  return (
    <div className="flex flex-col justify-center gap-1.5 w-full erg-info-content" data-testid="erg-info-content">
      {title && <h6 className="heading-6" data-qa="lister-item-title">{title}</h6>}
      {content}
    </div>
  );
};

export default InfoContent;
