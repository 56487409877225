import React from 'react';
import Badge from '../Badge';
import {CountBadgeProps} from './types';

const CountBadge: React.FC<CountBadgeProps> = ({ count, flavor = 'attention' }) => {
  if (!count || count <= 0) {
    return null;
  }
  return <Badge flavor={flavor} value={count} />;
};

export default CountBadge;
