import {ChevronLeftIcon, ChevronRightIcon} from '@ergeon/icons/hero/micro';
import React, {SetStateAction} from 'react';
import Button from '../../atoms/Button';

/**
 * Navigation Controls: Next/Previous Buttons
 */
interface NavigationControlsProps {
  imagesLength: number;
  setActiveIndex: React.Dispatch<SetStateAction<number>>;
}

const NavigationControls: React.FC<NavigationControlsProps> = ({imagesLength, setActiveIndex}) => {
  const onNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % imagesLength);
  };

  const onPrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + imagesLength) % imagesLength);
  };
  return (
    <>
      <Button
        taste="line"
        flavor="regular"
        size="small"
        isSquare
        className="top-1/2 left-2 z-10 absolute opacity-0 group-hover:opacity-100 transition -translate-y-3"
        onClick={onPrev}
      >
        <ChevronLeftIcon className="size-4" />
      </Button>
      <Button
        taste="line"
        flavor="regular"
        size="small"
        isSquare
        className="top-1/2 right-2 z-10 absolute opacity-0 group-hover:opacity-100 width-24 transition -translate-y-3"
        onClick={onNext}
      >
        <ChevronRightIcon className="size-4" />
      </Button>
    </>
  );
}

export default NavigationControls;
