import React from 'react';
import { MenuRowSimpleProps } from './types';

const MenuRowSimple: React.FC<MenuRowSimpleProps> = ({
  title,
  leftNode,
  rightNode,
  asAnchor,
  isBottomBordered,
  isActive,
  className = '',
  ...props
}) => {
  const Tag = asAnchor ? 'a' : 'div';

  return (
    <Tag
      className={`
        flex justify-between items-center
        py-2.5 pl-4 pr-8
        cursor-pointer
        transition-all duration-200 ease-in-out
        ${isActive ? 'bg-blue-100 hover:bg-blue-100' : 'hover:bg-gray-100'}
        ${isBottomBordered ? 'shadow-[inset_0_-1px_0_0_#E2E2EA]' : ''}
        ${className}
      `}
      {...(asAnchor ? {href: '#'} : {})}
      {...props}
    >
      <div className="flex items-center gap-1.5">
        <div className="flex justify-center items-center w-4 min-w-4 h-4 text-sm">{leftNode}</div>
        <div className={`text-sm text-gray-900 ${isActive ? 'font-semibold' : 'font-normal'}`}>
          <span>{title}</span>
        </div>
      </div>
      <div className="flex justify-center items-center text-sm">{rightNode}</div>
    </Tag>
  );
};

export default MenuRowSimple;
