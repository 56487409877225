import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

type DropdownButtonProps = HTMLAttributes<HTMLButtonElement>;

const DropdownButton = (props: DropdownButtonProps) => {
  const { className, ...otherProps } = props;

  return <button className={classNames('Dropdown-button', className)} {...otherProps} />;
};

export default DropdownButton;
