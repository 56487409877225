import React, {HTMLAttributes} from 'react';
import {ReactSVG} from 'react-svg';

import Dialog, {DialogContent, DialogHeader, DialogButtons} from '../../organisms/UniversalDialog';
import Button from '../../atoms/Button';

import iconCall from '@ergeon/icons/svg/icon-call.svg';
import iconEmail from '@ergeon/icons/svg/icon-email.svg';

import './FeedBackDialog.scss';

interface FeedBackDialogProps extends HTMLAttributes<HTMLDivElement> {
  onClose(): void;
  onEmail?(): void;
  onCall?(): void;
  callTitle: string;
  emailTitle: string;
}

const FeedBackDialog = (props: FeedBackDialogProps) => {
  const {onClose, onCall, onEmail, title, callTitle, emailTitle, children, ...divProps} = props;

  return (
    <Dialog {...divProps} className="FeedBackDialog" isVisible>
      <DialogHeader {...{onClose, title}} />
      <DialogContent>{children}</DialogContent>
      <DialogButtons>
        <Button flavor="action" onClick={onEmail}>
          <ReactSVG className="email-icon FeedBackDialog-icon" src={iconEmail} />
          {emailTitle}
        </Button>
        <Button flavor="primary" onClick={onCall}>
          <ReactSVG className="call-icon FeedBackDialog-icon" src={iconCall} />
          {callTitle}
        </Button>
      </DialogButtons>
    </Dialog>
  );
};

export default FeedBackDialog;
