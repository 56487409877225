import React from 'react';
import classNames from 'classnames';
import ListerImage from './ListerImage';
import ListerContent from './ListerContent';
import ListerButtons from './ListerButtons';
import Card from '../../atoms/Card';
import {ListerItemProps} from './types';

const ListerItem: React.FC<ListerItemProps> = ({items, title, buttons, className, images, ...divAttr}) => {
  const wrapperClasses = classNames('erg-lister-item flex p-5 gap-5 max-sm:flex-col', className);

  return (
    <Card data-testid="erg-lister-item" flavor="regular" rounded="small" zIndex="0" {...divAttr} className={wrapperClasses}>
      <ListerImage images={images} fit="cover" aspect="square" />
      <ListerContent title={title} items={items} />
      <ListerButtons buttons={buttons} />
    </Card>
  );
};

export default ListerItem;
