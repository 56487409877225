import React from "react";
import {ActionsProps} from "./types";

const QuoteLineActions: React.FC<ActionsProps> = ({ actionItems }) => {
  if (!actionItems || actionItems.length === 0) return null;
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row flex-wrap gap-1.5">
        {actionItems.map((elem, i) => (
          <React.Fragment key={i}>{elem}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default QuoteLineActions;