import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import CollapsibleToggle from './CollapsibleToggle';
import CollapsibleWrapper from './CollapsibleWrapper';
import type { MainMenuButton, MainMenuInnerButton } from './types';

export interface DropdownInnerCollapsibleProps extends HTMLAttributes<HTMLDivElement> {
  button: MainMenuInnerButton;
  renderDropdownButton(button: MainMenuButton): JSX.Element;
}

const DropdownInnerCollapsible = (props: DropdownInnerCollapsibleProps) => {
  const { button, className, renderDropdownButton, ...divProps } = props;

  const [isSelectorOpen, setSelectorOpen] = useState(false);

  const isDividerShown = useMemo(() => (
    !button.collapsible || isSelectorOpen
  ), [button.collapsible, isSelectorOpen]);

  const onToggleSelector = useCallback(() => {
    setSelectorOpen(isSelectorOpen => !isSelectorOpen);
  }, []);

  return (
    <div {...divProps} className={classNames('DropdownInner', className)}>
      <CollapsibleToggle button={button} onToggleSelector={onToggleSelector} />
      <div className="DropdownInner-divider" />
      <CollapsibleWrapper isActive={!!button.collapsible} isOpen={isSelectorOpen}>
        {button.buttons.map(renderDropdownButton)}
      </CollapsibleWrapper>
      {isDividerShown &&
        <div className="DropdownInner-divider" />
      }
    </div>
  );
};

export default DropdownInnerCollapsible;
