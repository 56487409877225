import React from 'react';

type MessageTextFormatterProps = {
  text: string;
};

const MessageTextFormatter: React.FC<MessageTextFormatterProps> = ({text}) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

  const parts = text.split(/(https?:\/\/[^\s]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g);

  return (
    <span>
      {parts.map((part, index) => {
        if (urlRegex.test(part)) {
          return (
            <a className="break-all" key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        } else if (emailRegex.test(part)) {
          return (
            <a className="break-all" key={index} href={`mailto:${part}`}>
              {part}
            </a>
          );
        }
        return part;
      })}
    </span>
  );
};

export default MessageTextFormatter;
