import React, {forwardRef, HTMLAttributes, useCallback, useEffect, useMemo} from 'react';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import es from 'date-fns/locale/es';
import omit from 'lodash/omit';

import DatePickerNav from './DatePickerNav';

import './index.scss';

const DEFAULT_LOCALE = 'en';
const AVAILABLE_LOCALES = [DEFAULT_LOCALE, 'es'];
const DIV_INVALID_ATTR = ['dayClassName', 'renderDayContents'];

// Use Spanish language
registerLocale('es', es);

interface DatePickerProps extends Omit<ReactDatePickerProps, 'value'> {
  value: Date;
  onChange(newValue: Date): void;
  isVisible: boolean;
  onClose(): void;
  monthOpen: Date;
  onMonthChange(newMonth: Date): void;
  autoClose?: boolean;
  locale?: string; // fallbacks to EN
  includeDates?: Date[]; 
  renderCustomHeader?: (params: ReactDatePickerCustomHeaderProps & {locale?: string}) => JSX.Element;
}

const DatePicker = forwardRef<HTMLDivElement, DatePickerProps & HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const autoClose = props.autoClose ?? true;
  const {
    value,
    onChange,
    isVisible,
    onClose,
    monthOpen,
    onMonthChange,
    includeDates,
    locale = 'en',
    renderCustomHeader: RenderCustomHeader = DatePickerNav,
    ...reactDatePickerNativeProps
  } = props;

  const availableLocale = useMemo(() => {
    if (/^en/.test(locale)) return 'en'; // map `en-US`, `en-GB` to `en`
    if (AVAILABLE_LOCALES.includes(locale)) return locale;
    return DEFAULT_LOCALE; // fallback
  }, [locale]);

  const onDateChange = useCallback(
    (selectedDate: Date) => {
      onChange(selectedDate);
      if (autoClose) {
        onClose();
      }
    },
    [autoClose, onChange, onClose],
  );

  useEffect(
    function updateMonth() {
      onMonthChange(value);
    },
    [value],
  );

  if (!isVisible) return null;

  const divAttr = omit(reactDatePickerNativeProps, DIV_INVALID_ATTR) as unknown as HTMLAttributes<HTMLDivElement>;
  const pickerAttr = reactDatePickerNativeProps as unknown as Omit<ReactDatePickerProps, 'value'>;

  return (
    <div {...divAttr} className="Calendar" ref={ref}>
      <ReactDatePicker
        {...pickerAttr}
        className="Calendar-datePicker"
        inline
        onChange={onDateChange}
        renderCustomHeader={(props) => <RenderCustomHeader {...props} locale={locale} />}
        fixedHeight
        onMonthChange={onMonthChange}
        openToDate={monthOpen}
        selected={value}
        disabledKeyboardNavigation
        locale={availableLocale}
        includeDates={includeDates}
      />
    </div>
  );
});

export default DatePicker;
