import range from 'lodash/range';
import React from 'react';

/**
 * Dots Navigation (indicator for multiple images)
 */
interface DotNavigationProps {
  imagesLength: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const DotNavigation: React.FC<DotNavigationProps> = ({ imagesLength, activeIndex, setActiveIndex }) => (
  <div className="bottom-3 z-10 absolute flex justify-center items-center gap-1 opacity-0 group-hover:opacity-100 w-full width-24">
    {range(imagesLength).map((_, index) => (
      <div
        key={index}
        onClick={() => setActiveIndex(index)}
        className={
          index === activeIndex
            ? 'bg-white size-2 transition rounded-full cursor-pointer'
            : 'bg-white/50 size-1 transition rounded-full hover:bg-white cursor-pointer'
        }
      />
    ))}
  </div>
);

export default DotNavigation;
