import React from 'react';
import ImageSlider, {ImageSliderProps} from '../../molecules/ImageSlider';

const ListerImage: React.FC<ImageSliderProps> = ({images, fit, aspect}) => {
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div className="w-full sm:max-w-32 erg-lister-image" data-testid="erg-lister-image">
      <ImageSlider images={images} fit={fit} aspect={aspect} />
    </div>
  );
};

export default ListerImage;
