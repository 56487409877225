import isTouchDevice from './isTouchDevice';

const CLICK_BEGIN_EVENT_NAME = isTouchDevice() ? 'touchstart' : 'mousedown';

const addOutsideClick = (callback: (event: MouseEvent | TouchEvent) => void) => {
  document.addEventListener(CLICK_BEGIN_EVENT_NAME, callback);
  return () => {
    document.removeEventListener(CLICK_BEGIN_EVENT_NAME, callback);
  };
};

export default addOutsideClick;
