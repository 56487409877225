import React, {useCallback, useState, ReactNode} from 'react';

import Dialog, {DialogHeader} from '../../organisms/UniversalDialog';
import {AnimatedPopup} from '../../atoms/UniversalPopUp';
import Button from '../../atoms/Button';

import './LeadWizard.scss';

export type LeadWizardProps = {
  isVisible?: boolean;
  handleClose: () => void;
  children: ReactNode;
};

const LeadWizard = ({isVisible = false, handleClose, children}: LeadWizardProps) => {
  const [isExitConfirmationVisible, setIsExitConfirmationVisible] = useState(false);
  const [isWizardVisible, setIsWizardVisible] = useState(isVisible);

  const handleAskToConfirmClose = () => {
    setIsExitConfirmationVisible(true);
  };

  const handleCloseAll = useCallback(() => {
    setIsExitConfirmationVisible(false);
    setIsWizardVisible(false);
    handleClose();
  }, [handleClose]);

  const handleNotClose = () => {
    setIsExitConfirmationVisible(false);
  };

  return (
    <AnimatedPopup
      isVisible={isWizardVisible}
      className="lead-wizard-wrapper"
      onChangeVisible={handleAskToConfirmClose}
    >
      <AnimatedPopup
        isVisible={isExitConfirmationVisible}
        className="lead-wizard-confirm-close"
        onChangeVisible={handleNotClose}
      >
        <Dialog isVisible={isExitConfirmationVisible} className="lead-wizard-confirm-close-dialog">
          <div className="lead-wizard-confirm-close-text-group">
            <b>You're almost done</b>
            <p>If you cancel now you'll lose your progress</p>
          </div>
          <div className="lead-wizard-confirm-close-action-group">
            <Button flavor="cta" onClick={handleNotClose}>
              Continue request
            </Button>

            <Button flavor="regular" taste="line" onClick={handleCloseAll}>
              Cancel request
            </Button>
          </div>
        </Dialog>
      </AnimatedPopup>

      <Dialog isVisible={isWizardVisible}>
        <DialogHeader onClose={handleAskToConfirmClose} />
        {children}
      </Dialog>
    </AnimatedPopup>
  );
};

export default LeadWizard;
