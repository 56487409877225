import React, {useState} from 'react';
import ImageFallback from './ImageFallback';
import {ImageFit, ImageAspect} from './types';

/**
 * Renders the background (blur) image and the foreground (preview) image.
 * Also handles onLoad/onError events for fallback logic.
 */

export interface ImageContainerProps {
  src: string;
  altText?: string;
  hasHover?: boolean;
  fit?: ImageFit;
  aspect?: ImageAspect;
}

const ImageContainer: React.FC<ImageContainerProps> = ({
  src,
  altText,
  hasHover,
  fit = 'contain',
  aspect = 'square',
}) => {
  const [showFallback, setShowFallback] = useState(true);

  const handleImageLoad = () => {
    setShowFallback(false);
  };

  const handleImageError = () => {
    setShowFallback(true);
  };

  const blurImageClasses = [
    'absolute w-full h-full object-cover',
    showFallback ? 'opacity-0' : 'opacity-100',
    hasHover ? 'group-hover:brightness-50 blur-md hover:brightness-50' : 'blur-md',
  ].join(' ');

  const previewClasses = [
    'relative w-full h-full',
    `object-${fit}`,
    showFallback ? 'opacity-0' : 'opacity-100',
    hasHover ? 'group-hover:brightness-50 hover:brightness-50' : '',
  ].join(' ');

  return (
    <div
      className={`relative bg-gray-200 outline-1 outline-gray-300 outline rounded w-full overflow-hidden aspect-${aspect}`}
    >
      {showFallback && <ImageFallback />}
      <img 
        src={src}
        alt="background"
        className={blurImageClasses}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
      <img src={src} alt={altText} className={previewClasses} onLoad={handleImageLoad} onError={handleImageError} />
    </div>
  );
};

export default ImageContainer;
