import React, {Dispatch, useEffect, useMemo, useState} from 'react';

import classNames from 'classnames';
import MenuRowSimple from '../../atoms/MenuRowSimple';
import {PlusIcon, MinusIcon} from '@ergeon/icons/hero/micro';

import './MenuRowAccordion.scss';

export interface MenuRowAccordionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  iconPosition?: 'left' | 'right';
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
  isBottomBordered?: boolean;
  setExpanded?: Dispatch<boolean>;
}

const MenuAccordionRow: React.FC<MenuRowAccordionProps> = ({
  title,
  children,
  iconPosition = 'left',
  className = '',
  expanded = false,
  leftNode,
  rightNode,
  isBottomBordered = false,
  setExpanded,
}) => {
  const [expandedState, setExpandedState] = useState(expanded);

  useEffect(() => {
    setExpandedState(expanded);
  }, [expanded]);

  const handleClick = () => {
    if (children) {
      setExpandedState(!expandedState);
      if (setExpanded) {
        setExpanded(!expandedState);
      }
    }
  };

  const contentClasses = `accordion-content ${expandedState && isBottomBordered ? 'with-bottom-border' : ''}`;

  const icon = useMemo(
    () =>
      expandedState ? <MinusIcon className="text-gray-500 size-4" /> : <PlusIcon className="text-gray-500 size-4" />,
    [expandedState],
  );

  const effectiveLeftNode = leftNode || (iconPosition === 'left' && icon);

  const effectiveRightNode = rightNode || (iconPosition === 'right' && icon);

  return (
    <div className={classNames('menu-row-accordion', className)}>
      <MenuRowSimple
        leftNode={effectiveLeftNode}
        title={title}
        rightNode={effectiveRightNode}
        isBottomBordered={!expandedState && isBottomBordered}
        onClick={handleClick}
      />
      {expandedState && (
        <div className={contentClasses}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

export default MenuAccordionRow;
