import React, { useState } from 'react';
import classNames from 'classnames';
import InfoIcon from './InfoIcon';
import InfoButton from './InfoButton';
import InfoContent from './InfoContent';
import Card from '../../atoms/Card';
import { InfoCardProps } from './types';
import { BellIcon } from '@ergeon/icons/hero/outline';

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  icon = <BellIcon />,
  hasButton,
  content,
  flavor = 'primary',
  ...divAttr
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const wrapperClasses = classNames('erg-info-card flex p-5 gap-5');

  return (
    <Card
      data-testid="erg-info-card"
      flavor={flavor}
      rounded="small"
      zIndex={hasButton && isHovered ? '3' : '0'}
      {...divAttr}
      className={wrapperClasses}
      onMouseEnter={() => hasButton && setIsHovered(true)}
      onMouseLeave={() => hasButton && setIsHovered(false)}
    >
      <InfoIcon icon={icon} flavor={flavor} />
      <InfoContent title={title} content={content} />
      {hasButton && <InfoButton flavor={flavor} />}
    </Card>
  );
};

export default InfoCard;