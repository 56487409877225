import React, { HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import './TopPanel.scss';

interface TopPanelProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const TopPanel = (props: TopPanelProps) => {
  const {
    children,
    className,
    ...otherProps
  } = props;

  return (
    <header {...otherProps} className={classNames('top-panel', className)}>
      <nav className="top-panel__content wrapper-1180">
        {children}
      </nav>
    </header>
  );
};

export default TopPanel;
