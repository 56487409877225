import React, {HTMLAttributes, useMemo} from 'react';
import classNames from 'classnames';

import Dialog, {DialogContent, DialogClose} from '../../organisms/UniversalDialog';
import CheckMark from '../../atoms/CheckMark/CheckMark';

import './SuccessDialog.scss';

interface SuccessDialogProps extends HTMLAttributes<HTMLDivElement> {
  onClose(): void;
}

const SuccessDialog = (props: SuccessDialogProps) => {
  const {onClose, children, className, ...divProps} = props;

  const dialogClassName = useMemo(() => {
    return classNames('SuccessDialog SuccessDialog-close', className);
  }, [className]);

  return (
    <Dialog {...divProps} className={dialogClassName} isVisible>
      <DialogClose onClose={onClose} />
      <DialogContent>
        <CheckMark />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;
