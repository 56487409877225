import React from 'react';
import DataColumn from '../../atoms/DataColumn';
import {DataItem} from './types';

type ListerContentProps = {
  title?: string;
  items?: DataItem[];
};

const ListerContent: React.FC<ListerContentProps> = ({title, items}) => {
  if (!title && !items) {
    throw new Error('Either title or items must be provided.');
  }

  return (
    <div className="flex flex-col gap-3 w-full min-w-60 erg-lister-content" data-testid="erg-lister-content">
      {title && <h6 className="heading-6" data-qa="lister-item-title">{title}</h6>}
      {items?.map((item, index) => (
        <DataColumn key={index} title={item.title} value={item.value} defaultValue={item.defaultValue} />
      ))}
    </div>
  );
};

export default ListerContent;
