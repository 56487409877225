import React from "react";
import Card from "../../atoms/Card";
import {QuoteLineProps} from "./types";
import QuoteLineMedia from "./QuoteLineMedia";
import QuoteLineHeader from "./QuoteLineHeader";
import QuoteLineDescription from "./QuoteLineDescription";
import QuoteLineAppendix from "./QuoteLineAppendix";
import QuoteLineActions from "./QuoteLineActions";

const QuoteLine: React.FC<QuoteLineProps> = ({
  mediaData = [],
  title,
  description,
  price,
  badgeContent,
  appendToDescription,
  actionItems
}) => {
  return (
    <Card flavor="transparent" className="p-5 flex flex-row gap-5 items-stretch">
      <QuoteLineMedia mediaData={mediaData} />
      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-col gap-1.5">
          <QuoteLineHeader badgeContent={badgeContent} title={title} price={price} />
          <QuoteLineDescription description={description} />
          <QuoteLineAppendix appendToDescription={appendToDescription} />
        </div>
        <QuoteLineActions actionItems={actionItems} />
      </div>
    </Card>
  );
};

export default QuoteLine;
