import React from 'react';
import classNames from 'classnames';

import {ReactSVG} from 'react-svg';
import iconDropdown from '@ergeon/icons/svg/icon_drop_down.svg';

import {MainMenuInnerButton} from './types';

interface CollapsibleToggleProps {
  button: MainMenuInnerButton;
  onToggleSelector(): void;
}

const CollapsibleToggle = ({button: mainMenuButton, onToggleSelector}: CollapsibleToggleProps) => (
  <button
    className={classNames('DropdownInner-title', {'is-button': mainMenuButton.collapsible})}
    onClick={mainMenuButton.collapsible ? onToggleSelector : undefined}
  >
    <label className="label uppercase">{mainMenuButton.label}</label>
    {mainMenuButton.collapsible && <ReactSVG src={iconDropdown} />}
  </button>
);

export default CollapsibleToggle;
