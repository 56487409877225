import React from "react";
import {AppendixProps} from "./types";

const QuoteLineAppendix: React.FC<AppendixProps> = ({ appendToDescription }) => {
  if (!appendToDescription || appendToDescription.length === 0) return null;
  return (
    <div className="flex flex-col gap-1.5">
      {appendToDescription.map((elem, i) => (
        <React.Fragment key={i}>{elem}</React.Fragment>
      ))}
    </div>
  );
};

export default QuoteLineAppendix;