// components/molecules/EmptyState.tsx
import React from 'react';
import {ChatBubbleLeftRightIcon} from '@ergeon/icons/hero/outline';

const MessageFeedEmpty: React.FC = () => {
  return (
    <div className="flex flex-col items-center gap-2 text-center">
      <ChatBubbleLeftRightIcon className="text-gray-500 size-8" />
      <h5 className="text-gray-800 text-lg leading-tight">No messages to show</h5>
      <p className="max-w-44 text-gray-700 text-sm leading-normal">We will show your message history here</p>
    </div>
  );
};

export default MessageFeedEmpty;
