import React, {Children, forwardRef, HTMLAttributes, ReactElement, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {ReactSVG} from 'react-svg';

import iconBurger from '@ergeon/icons/svg/icon-burger.svg';
import iconCross from '@ergeon/icons/svg/icon-cross-gray.svg';

import Button from '../../../atoms/Button';
import {useDropdown, usePopper} from '../../../molecules/UniversalDropdown';
import {isItemCTA, isItemFixed} from './TopPanelNavItem';

import './TopPanelNav.scss';

export interface TopPanelNavListProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactElement | ReactElement[];
}

const TopPanelNavList = forwardRef<HTMLUListElement, TopPanelNavListProps>((props, ref) => {
  const {children, className, ...otherProps} = props;

  const getChildClassName = useCallback(
    (child: ReactElement) =>
      classNames({
        'is-CTA': isItemCTA(child),
      }),
    [],
  );

  return (
    <ul {...otherProps} className={classNames('top-panel__nav', className)} ref={ref}>
      {Children.map(children, (child: ReactElement, idx) => (
        <li className={getChildClassName(child)} key={child.props.key ?? idx}>
          {child}
        </li>
      ))}
    </ul>
  );
});

interface TopPanelNavDropdownProps extends TopPanelNavListProps {
  isVisible: boolean;
}

const TopPanelNavDropdown = forwardRef<HTMLUListElement, TopPanelNavDropdownProps>(
  ({className, isVisible, ...props}, ref) =>
    isVisible ? <TopPanelNavList {...props} className={classNames(className, 'is-dropdown')} ref={ref} /> : null,
);

interface TopPanelNavSideProps extends TopPanelNavListProps {}

export const TopPanelNavSide = forwardRef<HTMLUListElement, TopPanelNavSideProps>((props, ref) => (
  <TopPanelNavList {...props} ref={ref} />
));

interface TopPanelNavProps extends TopPanelNavListProps {}

const TopPanelNav = (props: TopPanelNavProps) => {
  const {children, ...otherProps} = props;

  const {anchorRef, dropdownRef} = usePopper<HTMLButtonElement>();
  const {setVisible, visible: isVisible} = useDropdown<HTMLButtonElement>({anchorRef, dropdownRef});

  const sideChildren = useMemo(() => {
    const childrenArray = Children.toArray(children) as ReactElement[];

    return childrenArray.filter(isItemFixed);
  }, [children]);

  const dropdownChildren = useMemo(() => {
    const childrenArray = Children.toArray(children) as ReactElement[];

    return childrenArray.filter((child) => !isItemFixed(child));
  }, [children, sideChildren]);

  const onToggle = useCallback(() => {
    setVisible((isVisible) => !isVisible);
  }, []);

  return (
    <>
      <div className="top-panel__mobile">
        {!isEmpty(dropdownChildren) && (
          <>
            <Button
              className="top-panel__nav--toggle"
              flavor="regular"
              onClick={onToggle}
              ref={anchorRef}
              size="large"
              taste="boundless"
            >
              {isVisible ? <ReactSVG src={iconCross} /> : <ReactSVG src={iconBurger} />}
            </Button>
            <TopPanelNavDropdown {...otherProps} isVisible={isVisible} ref={dropdownRef}>
              {dropdownChildren}
            </TopPanelNavDropdown>
          </>
        )}
        {!isEmpty(sideChildren) && <TopPanelNavSide className="is-fixed">{sideChildren}</TopPanelNavSide>}
      </div>
      <div className="top-panel__desktop">
        <TopPanelNavSide {...props} />
      </div>
    </>
  );
};

export default TopPanelNav;
