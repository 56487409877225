import React from 'react';
import Card from '../../atoms/Card';
import moment from 'moment';
import classNames from 'classnames';
import TextFormatter from './MessageTextFormatter';
import {MessageItemProps} from './types';
import ImageContainer from 'components/atoms/ImageContainer';

const MessageItem: React.FC<MessageItemProps> = ({text, created_at, type, attachments = []}) => {
  const containerClasses = classNames('flex flex-col gap-1 erg-message-item', {
    'self-end items-end': type === 'inbound',
    'self-start items-start': type !== 'inbound',
  });
  const cardFlavor = type === 'inbound' ? 'cta' : 'primary';
  const formattedDateTime = moment(created_at).format('MMM D, YYYY, hh:mm A');

  return (
    <div className={containerClasses}>
      <Card
        rounded="xsmall"
        className="flex flex-col px-4 py-2 max-w-md text-gray-900 text-sm leading-normal"
        flavor={cardFlavor}
      >
        <TextFormatter text={text} />

        {attachments?.length > 0 && (
          <div className="flex flex-wrap gap-2 my-2">
            {attachments.map((url, index) => (
              <ImageContainer aspect="auto" key={index} src={url} altText={`Attachment ${index + 1}`} />
            ))}
          </div>
        )}
      </Card>

      <div className="flex items-baseline gap-1">
        <span className="text-gray-500 text-xs leading-normal">{formattedDateTime}</span>
      </div>
    </div>
  );
};

export default MessageItem;
